import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Manaje" link="https://manaje-refactor.netlify.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  {
        /* <div>{title}</div> */
      }
  This project is a tool to manage teams and keep track of projects.
  <ul>
    <li>
      {' '}
      A project management and team communication app that keeps teams efficient
      and transparent.`}
    </li>
    <li>
      {' '}
      Technical highlight: Refactored legacy code using GraphQL and Prisma,
      switched from MongoDB to an SQL database so that Manaje could handle more
      complex data relationships, and added a new todo feature.
    </li>
    <li> Tech Stack: ReactJS, NodeJS, GraphQL, PostgreSQL, Material-UI.</li>
  </ul>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      